export const COLOR_CODE = {
  BLUE_THEME_COLOR: '#01A4EE',
  HEADING_BACKGROUND_AND_BORDERS: '#F0F0F0',
  HEADING_TEXT: '#8D8D8D',
  CLEAR_ALL: '#D2222D',
  BUTTON_GRAY_BACKGROUND: '#ECECEC',
  STATUS_DONE_COLOR: '#018A5E',
  STATUS_WAITING_COLOR: '#E29802',
  STATUS_CANCELLED_COLOR: '#D73A3A',
  ERROR_COLOR: '#D73A3A',
  GRAY_TEXT_COLOR: '#B5B5B5',
  BLACK: '#000',
  WHITE: '#fff',
  NOTIFICATION_VIEW: '#F9F9F9',
}
