import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { database } from '../../firebase'
import { ServiceContext } from './ServiceContext'

const ServiceProvider = ({ children }) => {
  const [servicesData, setServicesData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [filterSwitch, setFilterSwitch] = useState(false)
  const [loadingServicesData, setloadingServicesData] = useState(false)
  const [searchSwitch, setSearchSwitch] = useState(false)
  const [lastVisibleData, setLastVisibleData] = useState({})
  const [hasMore, setHasMore] = useState(true)
  const [totalServices, setTotalServices] = useState(0)
  const [notificationData, setnotificationData] = useState([])
  const [notificationLoader, setnotificationLoader] = useState(false)
  useEffect(() => {
    setloadingServicesData(true)
    fetchServicesData()
    fetchNotificationData()
  }, [])
  useEffect(() => {
    const q = query(collection(database, 'SERVICES'))
    getDocs(q).then((snap) => {
      setTotalServices(snap.size)
    })
  }, [servicesData])

  const fetchServicesData = async () => {
    let services = []
    const q = query(
      collection(database, 'SERVICES'),
      orderBy('createdAt', 'desc'),
      limit(15)
    )
    const documentSnapshots = await getDocs(q)
    const lastVisibledata =
      documentSnapshots.docs[documentSnapshots.docs.length - 1]
    setLastVisibleData(lastVisibledata)

    documentSnapshots.forEach((doc) => {
      services.push({ ...doc.data(), id: doc.id })
    })
    setServicesData(services)
    setloadingServicesData(false)
  }
  const filterData = async (data) => {
    let conditions = []

    if (data.statusSelect !== '') {
      conditions.push(where('status', '==', data.statusSelect))
    }

    if (data.assigneeSelect !== '') {
      conditions.push(where('assignTo', '==', data.assigneeSelect))
    }
    // if (data.monthSelect != '') {
    //   conditions.push(
    //     // where('service_schedule_date', 'array-contains-any', [
    //     //   `${data.monthSelect}`,
    //     where('service_schedule_date', 'in', data.monthSelect)
    //   )
    // }

    // if (data.yearSelect !== '' || data.monthSelect !== '') {
    //   if (data.yearSelect !== '' && data.monthSelect !== '') {
    //     conditions.push(
    //       where('service_schedule_date', 'array-contains', [
    //         `${data.monthSelect}`,
    //         `${data.yearSelect}`,
    //       ])
    //     )
    //   } else if (data.yearSelect == '' && data.monthSelect != '') {
    //     conditions.push(
    //       where('service_schedule_date', 'array-contains-any', [
    //         `${data.monthSelect}`,
    //       ])
    //     )
    //   } else {
    //     conditions.push(
    //       where('service_schedule_date', 'array-contains-any', [
    //         `${data.yearSelect}`,
    //       ])
    //     )
    //   }
    // }

    const filterQuery = query(collection(database, 'SERVICES'), ...conditions)
    const querySnapshot = await getDocs(filterQuery)

    let filterData = []
    querySnapshot.forEach((doc) => {
      filterData.push({ ...doc.data(), id: doc.id })
    })
    let monthFilter = []
    if (data.monthSelect !== '') {
      filterData.filter((filter) => {
        if (
          filter.service_schedule_date
            .toString()
            .toLowerCase()
            .includes(data.monthSelect.toLowerCase())
        )
          monthFilter.push(filter)
      })
    } else {
      monthFilter = filterData
    }

    setFilterSwitch(true)
    setFilteredData(monthFilter)
  }
  const filterSearch = async (data) => {
    if (data !== '') {
      const searchQuery = query(collection(database, 'SERVICES'))
      const querySnapshot = await getDocs(searchQuery)
      let searchData = []
      querySnapshot.forEach((doc) => {
        searchData.push({ ...doc.data(), id: doc.id })
      })

      let searchFilter = _.filter(searchData, (search) => {
        if (
          search.title.toLowerCase().includes(data.toLowerCase()) ||
          search.companyName.toLowerCase().includes(data.toLowerCase())
        )
          return data
      })
      setSearchSwitch(true)
      setFilteredData(searchFilter)
    } else {
      setSearchSwitch(false)
      setFilteredData(servicesData)
    }
  }
  const nextClick = async (amount) => {
    let services = []
    const q = query(
      collection(database, 'SERVICES'),
      orderBy('createdAt', 'desc'),
      limit(amount),
      startAfter(lastVisibleData)
    )
    const documentSnapshots = await getDocs(q)
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1]
    setLastVisibleData(lastVisible)

    documentSnapshots.forEach((doc) => {
      services.push({ ...doc.data() })
    })
    if (services.length > 0) {
      setServicesData([...servicesData, ...services])
    } else {
      setHasMore(false)
    }
    setloadingServicesData(false)
  }
  const fetchNotificationData = async () => {
    let notificationArray = []
    setnotificationLoader(true)
    const q = query(
      collection(database, 'NOTIFICATIONS'),
      orderBy('createdAt', 'desc')
    )
    const documentSnapshots = await getDocs(q)
    documentSnapshots.forEach((doc) => {
      notificationArray.push({ ...doc.data(), id: doc.id })
    })
    setnotificationData(notificationArray)

    setTimeout(() => {
      setnotificationLoader(false)
    }, 1000)
  }
  return (
    <ServiceContext.Provider
      value={{
        servicesData,
        fetchServicesData,
        loadingServicesData,
        filterData,
        filterSwitch,
        setFilterSwitch,
        filterSearch,
        setSearchSwitch,
        searchSwitch,
        nextClick,
        hasMore,
        setHasMore,
        filteredData,
        totalServices,
        notificationData,
        fetchNotificationData,
        notificationLoader,
      }}
    >
      {children}
    </ServiceContext.Provider>
  )
}

export default ServiceProvider
