import { addDoc, collection, getDocs, Timestamp } from 'firebase/firestore'
import moment from 'moment'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { AlertContext } from '../../Context/Alert/AlertContext'
import { database } from '../../firebase'
import Nextschedule from '../Nextschedule'
import Addcontact from './addcontact'
import CreateScheduleForm from './CreateScheduleForm'
import './style.css'

export default function Adddetailsform({ closeModal, data, isEdit }) {
  const [currentStep, setCurrentStep] = useState(1)
  const [profile, setProfile] = useState({
    status: '',
    assignTo: '',
    title: '',
    note: '',
    companyName: '',
    contact_person_name: '',
    contact_number: '',
    service_schedule_date: moment(new Date()).format('DD MMMM YYYY'),
    next_service_schedule_date: moment(new Date()).format('DD MMMM YYYY'),
  })
  const [location, setLocation] = useState({
    address: isEdit ? data.location?.address : '',
    city: isEdit ? data.location?.city : '',
  })
  const { setMessage, setShow } = useContext(AlertContext)

  useEffect(() => {
    setProfile(isEdit ? data : profile)
  }, [])

  const onPressNext = (nextStepValue) => {
    setCurrentStep(nextStepValue)
  }

  const handleScheduleDateInput = (date_value) => {
    setProfile({ ...profile, service_schedule_date: date_value })
  }
  const handleNextScheduleDateInput = (date_value) => {
    setProfile({ ...profile, next_service_schedule_date: date_value })
  }

  const handleProfile = (event) => {
    event.preventDefault()
    const target = event.target
    const values = target.value
    const name = target.name
    if (name === 'address' || name === 'city') {
      setLocation({ ...location, [name]: values })
    } else {
      setProfile({ ...profile, [name]: values })
    }
  }

  const collectionRef = collection(database, 'SERVICES')
  const getData = () => {
    getDocs(collectionRef).then((response) => {})
  }
  const onSubmit = () => {
    let notes = []

    let createdAt = Timestamp.now()
    if (data && data?.note) {
      notes.push(...data?.note, {
        createdAt: createdAt,
        note: profile.note,
      })
    } else {
      notes.push({
        createdAt: createdAt,
        note: profile.note,
      })
    }
    const params = {
      ...profile,
      location,
      createdAt: createdAt,
      note: notes,
      service_schedule_date: profile.service_schedule_date,
      next_service_schedule_date: profile.next_service_schedule_date,
    }
    addDoc(collectionRef, params)
      .then(() => {
        setShow(true)
        setMessage({ text: 'Data Stored!', type: 'success' })
      })
      .catch((err) => {
        setShow(true)
        setMessage({ text: err.message, type: 'error' })
      })
    getData()
  }

  return (
    <Fragment>
      {currentStep === 1 ? (
        <CreateScheduleForm
          onPressNext={onPressNext}
          closeModal={closeModal}
          handleScheduleDateInput={handleScheduleDateInput}
          handleProfile={handleProfile}
          profile={{ ...profile, location }}
          setProfile={setProfile}
          data={data}
          isEdit={isEdit}
        />
      ) : (
        <Addcontact
          onPressNext={onPressNext}
          closeModal={closeModal}
          profile={profile}
          handleProfile={handleProfile}
          onSubmit={onSubmit}
          data={data}
          isEdit={isEdit}
        />
      )}
      <Nextschedule
        data={data}
        profile={profile}
        handleProfile={handleProfile}
        handleNextScheduleDateInput={handleNextScheduleDateInput}
        onSubmit={onSubmit}
      />
    </Fragment>
  )
}
