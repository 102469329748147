import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { collection, getDocs } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { app, database } from '../../firebase'
import { AlertContext } from '../Alert/AlertContext'
import { UserContext } from './UserContext'

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)
  const [userData, setUserData] = useState({})
  const [usersArray, setUsersArray] = useState([])
  const { setShow, setMessage } = useContext(AlertContext)

  const auth = getAuth(app)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
        setLoading(false)
      } else {
        setUser('')
        setLoading(false)
      }
    })
  }, [])

  const fetchUserData = () => {
    const userRef = collection(database, 'USERS')
    getDocs(userRef)
      .then((response) => {
        let users = {}
        let userArray = []
        response.docs.map((data) => {
          users[data.id] = { ...data.data() }
          userArray.push({ ...data.data(), id: data.id })
          return data
        })
        setUsersArray(userArray)
        setUserData(users)
      })
      .catch((err) => {
        setShow(true)
        setMessage({ text: err, type: 'error' })
      })
  }
  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        fetchUserData,
        userData,
        setUserData,
        usersArray,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
