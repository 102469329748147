import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import * as React from 'react'
import { Fragment } from 'react'
import Adddetailsform from '../Adddetailsform'

export default function Adddetailsmodal() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Fragment>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddCircleOutlinedIcon />}
      >
        Add
      </Button>
      <Dialog
        maxWidth={'md'}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent
          dividers
          onClose={handleClose}
          sx={{ borderRadius: '10px', padding: '20px' }}
        >
          <Adddetailsform closeModal={handleClose} isEdit={false} />
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
