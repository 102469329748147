import React, { useState } from 'react'
import { AlertContext } from './AlertContext'

const AlertProvider = ({ children }) => {
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState({ text: '', type: '' })
  const handleClose = () => {
    setShow(false)
  }
  return (
    <AlertContext.Provider
      value={{ show, setShow, message, setMessage, handleClose }}
    >
      {children}
    </AlertContext.Provider>
  )
}

export default AlertProvider
