import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Button, CircularProgress, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import * as React from 'react'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { ServiceContext } from '../../Context/Service/ServiceContext'
import { COLOR_CODE } from '../../util/Color'
export default function Notificationdetails({ closenotificationdrawer }) {
  const { notificationData, fetchNotificationData, notificationLoader } =
    React.useContext(ServiceContext)
  const getNotification = () => {
    return notificationData.map((data, index) => {
      let date = moment(new Date(data.createdAt.toDate())).format(
        'DD MMMM YYYY'
      )
      let time = moment(new Date(data.createdAt.toDate())).format('hh:mm A')
      return (
        <div
          className="notification-view"
          style={{ background: COLOR_CODE.NOTIFICATION_VIEW }}
          key={index}
        >
          <Typography
            variant="h5"
            style={{
              fontWeight: '400',
              fontSize: '14px',
              marginBottom: 0,
              color: COLOR_CODE.BLACK,
            }}
          >
            {data.title}
          </Typography>

          {data.body !== '' ? (
            <Typography
              variant="h5"
              style={{
                fontSize: '12px',
                color: COLOR_CODE.HEADING_TEXT,
                marginBottom: 0,
              }}
            >
              {data.body}
            </Typography>
          ) : null}

          <ul style={{ marginTop: '10px' }}>
            <li>{time}</li>
            <li>{date}</li>
          </ul>
        </div>
      )
    })
  }
  const refreshNotification = () => {
    fetchNotificationData()
  }
  return (
    <Fragment>
      <Paper elevation={0} className="filterheader">
        <Typography variant="h2">Notifications</Typography>
        <div style={{ display: 'flex' }}>
          <RefreshIcon
            onClick={refreshNotification}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          />
          <Link
            to="#"
            onClick={closenotificationdrawer}
            className="closedrawer"
          >
            <CloseIcon />
          </Link>
        </div>
      </Paper>

      <Box
        sx={{
          height: 'calc(100vh - 160px)',
          overflow: 'auto',
          flexDirection: 'column',
        }}
      >
        {notificationLoader && (
          <div
            style={{
              justifyContent: 'center',
              alignSelf: 'center',
              display: 'flex',
            }}
          >
            <CircularProgress size={'20px'} style={{ margin: '10px' }} />
          </div>
        )}

        {getNotification()}
      </Box>

      <Button
        disableRipple={true}
        className="graybtn"
        sx={{ width: '90%', marginLeft: '20px', marginTop: '20px' }}
      >
        Clear all
      </Button>
    </Fragment>
  )
}
