import { Logout } from '@mui/icons-material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import SettingsIcon from '@mui/icons-material/Settings'
import { Avatar, Menu, MenuItem, Paper } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import { getAuth, signOut } from 'firebase/auth'
import * as React from 'react'
import { app } from '../../firebase'
import Notificationdrawer from '../Notificationdrawer'
import Updatepassword from '../Updatepassword'
import './styles.css'

export default function Header() {
  const auth = getAuth(app)
  const [anchorSettingsEl, setAnchorSettingsEl] = React.useState(null)
  const openSettings = Boolean(anchorSettingsEl)

  const handleClick = (event) => {
    setAnchorSettingsEl(event.currentTarget)
  }

  const handleSettingsClose = () => {
    setAnchorSettingsEl(null)
  }

  const logout = () => {
    signOut(auth)
    handleSettingsClose()
  }

  const [notificationDrawer, setNotificationDrawer] = React.useState(false)

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <img src={'images/logo.svg'} width="170px" height="40px" alt="" />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { md: 'flex', gap: '20px' } }}>
            <Paper elevation={0} className="toolbarmain">
              <IconButton
                className="toolbar-icon-button"
                disableRipple={true}
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={() => setNotificationDrawer(true)}
              >
                <Badge badgeContent={0} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Paper>

            <Paper
              elevation={0}
              className="toolbarmain"
              id="settings-button"
              aria-controls={openSettings ? 'setting-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openSettings ? 'true' : undefined}
            >
              <IconButton
                className="toolbar-icon-button"
                disableRipple={true}
                size="large"
                color="inherit"
                onClick={handleClick}
              >
                <Badge color="error">
                  <SettingsIcon />
                </Badge>
              </IconButton>
            </Paper>
            <Menu
              anchorEl={anchorSettingsEl}
              id="setting-menu"
              open={openSettings}
              onClose={handleSettingsClose}
              MenuListProps={{
                'aria-labelledby': 'settings-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem>
                <Updatepassword handleSettingsClose={handleSettingsClose} />
              </MenuItem>

              <MenuItem style={{ gap: 10 }} onClick={logout}>
                <Logout /> Logout
              </MenuItem>
            </Menu>
            <Avatar
              alt="avatar"
              variant="rounded"
              className="avtarrounded"
              sx={{ width: 40, height: 40 }}
              src="https://picsum.photos/40"
            />
          </Box>
        </Toolbar>
      </AppBar>

      <Notificationdrawer {...{ notificationDrawer, setNotificationDrawer }} />
    </Box>
  )
}
