import { Button, Divider, TextField, Typography } from '@mui/material'

import { doc, setDoc } from 'firebase/firestore'
import React, { Fragment, useContext, useState } from 'react'
import { COLOR_CODE } from '../../util/Color'
import { AlertContext } from '../../Context/Alert/AlertContext'
import { ServiceContext } from '../../Context/Service/ServiceContext'
import { UserContext } from '../../Context/User/UserContext'
import { database } from '../../firebase'
import './style.css'

export default function Addcontact({
  onPressNext,
  closeModal,
  onSubmit,
  handleProfile,
  profile,
  isEdit,
  data,
}) {
  const { companyName, contact_person_name, contact_number } = profile
  const [companyNamevalidate, setCompanyNameValidate] = useState('')
  const [contactPersonValidate, setContactPersonValidate] = useState('')
  const [contactNumberValidate, setContactNumberValidate] = useState('')
  const { setShow, setMessage } = React.useContext(AlertContext)
  const { fetchServicesData } = useContext(ServiceContext)
  const { fetchUserData } = useContext(UserContext)

  const update = (updateValue) => {
    setDoc(doc(database, 'SERVICES', data.id), updateValue)
  }
  const onSubmitForm = () => {
    let error = true
    if (profile.companyName.trim() === '') {
      error = true
      setCompanyNameValidate('*This field is required')
    } else {
      error = false
      setCompanyNameValidate('')
    }
    if (profile.contact_person_name.trim() === '') {
      error = true
      setContactPersonValidate('*This field is required')
    } else {
      error = false
      setContactPersonValidate('')
    }
    if (profile.contact_number.trim() === '') {
      error = true
      setContactNumberValidate('*This field is required')
    } else {
      error = false
      setContactNumberValidate('')
    }

    if (
      !error &&
      profile.companyName &&
      profile.contact_person_name &&
      profile.contact_number
    ) {
      if (isEdit) {
        update(profile)
        setShow(true)
        setMessage({ text: 'status Updated', type: 'success' })
        fetchUserData()
        closeModal()
        fetchServicesData()
      } else {
        onSubmit()
        closeModal()
        fetchServicesData()
      }
    }
  }
  return (
    <Fragment>
      <Typography mb={'20px'} variant="h2">
        Add Contact Details
      </Typography>
      <Divider sx={{ margin: '0 -20px 20px -20px' }} />
      <form noValidate className="adddetailsmain">
        <div className="form-group">
          <label>Company Name</label>
          <TextField
            disabled={isEdit ? true : false}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={companyName}
            name="companyName"
            placeholder="Enter Company Name"
            className="form-control"
            onChange={handleProfile}
          />
          {companyNamevalidate !== '' ? (
            <text style={{ color: COLOR_CODE.ERROR_COLOR, fontSize: '14px' }}>
              {companyNamevalidate}
            </text>
          ) : null}
        </div>
        <div className="form-group">
          <label>Contact Person Name</label>
          <TextField
            name="contact_person_name"
            variant="outlined"
            margin="normal"
            required
            value={contact_person_name}
            fullWidth
            onChange={handleProfile}
            placeholder="Enter Contact Person Name"
            className="form-control"
          />
          {contactPersonValidate !== '' ? (
            <text style={{ color: COLOR_CODE.ERROR_COLOR, fontSize: '14px' }}>
              {contactPersonValidate}
            </text>
          ) : null}
        </div>
        <div className="form-group">
          <label>Contact Number</label>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={contact_number}
            name="contact_number"
            onChange={handleProfile}
            placeholder="Enter Contact Number"
            className="form-control"
          />
          {contactNumberValidate !== '' ? (
            <text style={{ color: COLOR_CODE.ERROR_COLOR, fontSize: '14px' }}>
              {contactNumberValidate}
            </text>
          ) : null}
        </div>

        <div className="fullwidthcontactdetails">
          <Button
            style={{
              letterSpacing: '0.1em',
              color: COLOR_CODE.BLACK,
              width: 150,
            }}
            type="button"
            onClick={() => onPressNext(1)}
            variant="contained"
            color="secondary"
          >
            Back
          </Button>
          <Button
            style={{ letterSpacing: '0.1em', width: 150 }}
            variant="contained"
            color="primary"
            onClick={onSubmitForm}
          >
            {isEdit ? 'Update' : 'Submit'}
          </Button>
        </div>
      </form>
    </Fragment>
  )
}
