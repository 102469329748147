import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import ActionsMenu from '../../component/Actions/ActionsMenu'
import Footer from '../../component/Footer'
import Header from '../../component/Header'
import Searchbar from '../../component/Searchbar'
import StatusSelect from '../../component/StatusSelect/StatusSelect'
import { ServiceContext } from '../../Context/Service/ServiceContext'
import { UserContext } from '../../Context/User/UserContext'
import { COLOR_CODE } from '../../util/Color'
import Splash from '../splash/Splash'
import './style.css'

const Dashboard = () => {
  const [tableData, setTableData] = useState([])
  const [searchText, setSearchText] = useState('')
  const { fetchUserData, userData } = useContext(UserContext)
  const {
    servicesData,
    loadingServicesData,
    filterSwitch,
    searchSwitch,
    nextClick,
    hasMore,
    filteredData,
    totalServices,
  } = useContext(ServiceContext)
  const tableEl = useRef()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchUserData()
  }, [])

  const [distanceBottom, setDistanceBottom] = useState(0)

  const loadMore = useCallback(() => {
    const loadItems = async () => {
      await new Promise((resolve) =>
        setTimeout(() => {
          const amount = servicesData.length === 0 ? 15 : servicesData.length
          nextClick(amount)
          setLoading(false)
          resolve()
          if (amount === servicesData.length) {
            setLoading(false)
          }
        }, 500)
      ).catch(() => setLoading(false))
    }
    setLoading(true)

    loadItems()
  }, [servicesData])

  const scrollListener = useCallback(() => {
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight
    if (!distanceBottom) {
      setDistanceBottom(Math.round((bottom / 20) * 40))
    }
    if (
      tableEl.current.scrollTop > bottom - distanceBottom &&
      hasMore &&
      !loading
    ) {
      if (totalServices > servicesData.length) loadMore()
    }
  }, [hasMore, loadMore, loading, distanceBottom])

  useLayoutEffect(() => {
    const tableRef = tableEl.current
    tableRef.addEventListener('scroll', scrollListener)
    return () => {
      tableRef.removeEventListener('scroll', scrollListener)
    }
  }, [scrollListener])

  useEffect(() => {
    setTableData(servicesData)
  }, [servicesData])

  useEffect(() => {
    if (filterSwitch || searchSwitch) setTableData(filteredData)
  }, [filteredData])

  const clearAll = () => {
    setTableData(servicesData)
  }
  const searchdata = (data) => {
    if (data == '') {
      setTimeout(() => {
        setTableData(servicesData)
      }, 500)
    }
  }

  return (
    <div className="dashboard-main">
      <Header />
      <Searchbar
        clearAll={clearAll}
        searchData={searchdata}
        setSearchText={setSearchText}
      />
      <div className="datatablemain">
        <Paper className="tablepaper">
          <TableContainer
            ref={tableEl}
            style={{ height: 'calc(100vh - 270px)' }}
          >
            <Table component={Table}>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>AssignTo</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingServicesData === true ? (
                  <TableRow>
                    <TableCell
                      style={{ fontSize: '18px' }}
                      colSpan={7}
                      align="center"
                      scope="row"
                    >
                      <Splash />
                    </TableCell>
                  </TableRow>
                ) : tableData == '' ? (
                  <TableRow>
                    <TableCell
                      style={{
                        color: COLOR_CODE.GRAY_TEXT_COLOR,
                        fontSize: '18px',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                      colSpan={7}
                      align="center"
                      scope="row"
                    >
                      No Data Found
                    </TableCell>
                  </TableRow>
                ) : (
                  tableData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell
                        component="td"
                        scope="row"
                        style={{ width: 400 }}
                      >
                        {row?.title}
                      </TableCell>
                      <TableCell align="center">
                        {row.companyName === '' ? 'empty' : row.companyName}
                      </TableCell>
                      <TableCell align="center">
                        {row.service_schedule_date}
                      </TableCell>
                      <TableCell align="center">
                        <StatusSelect data={row} />
                      </TableCell>
                      <TableCell align="center">
                        {userData[row.assignTo]?.fullName}
                      </TableCell>
                      <TableCell align="center">{row.location?.city}</TableCell>
                      <TableCell align="center">
                        <ActionsMenu data={row} />
                      </TableCell>
                    </TableRow>
                  ))
                )}
                {loading && (
                  <TableRow>
                    <TableCell
                      style={{ width: '100%', textAlign: 'center' }}
                      colSpan={7}
                      align="center"
                      scope="row"
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>

      <Footer />
    </div>
  )
}

export default Dashboard
