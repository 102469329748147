import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Nextschedule from '../Nextschedule'

import { doc, setDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import _ from 'lodash'
import * as React from 'react'
import { STATUS_CODE } from '../../Constants/StatusConst'
import { AlertContext } from '../../Context/Alert/AlertContext'
import { UserContext } from '../../Context/User/UserContext'
import { database, functions } from '../../firebase'
import './style.css'

const StatusSelect = ({ data }) => {
  const [currentStatus, setCurrentStatus] = React.useState(data.status)
  const { setShow, setMessage } = React.useContext(AlertContext)
  const { usersArray } = React.useContext(UserContext)
  const fcmToken = _.map(usersArray, (user) => {
    return user.fcmToken
  }).filter((data) => {
    if (data !== '') {
      return data
    }
  })
  const handleChange = async (event) => {
    setCurrentStatus(event.target.value)
    data.status = event.target.value
    const docRef = doc(database, 'SERVICES', data.id)

    const statusData = {
      ...data,
      status: event.target.value,
    }
    setDoc(docRef, statusData)
      .then((docRef) => {
        setShow(true)
        setMessage({ text: 'status Updated', type: 'success' })
      })
      .catch((error) => {
        setShow(true)
        setMessage({ text: error, type: 'error' })
      })

    const sendPushToAll = httpsCallable(functions, 'sendServiceNotification')
    await sendPushToAll({
      title: data.title + ' status changed to ' + event.target.value,
      body: '',
      usersFcmToken: fcmToken,
      serviceId: data.id,
      assignTo: data.assignTo,
    })
  }

  const [showScheduleModel, setShowScheduleModel] = React.useState(false)
  const closeClick = () => {
    setShowScheduleModel(false)
  }
  const statusIndicator = (status) => {
    if (status === STATUS_CODE.DONE) {
      return 'success widthdashbord selectinput'
    } else if (status === STATUS_CODE.WAITING_FOR_RESPONSE) {
      return 'warning widthdashbord selectinput'
    } else if (status === STATUS_CODE.CANCELLED) {
      return 'error widthdashbord selectinput'
    }
  }
  return (
    <Box sx={{ minWidth: '100%' }}>
      <Select
        labelId="demo-simple-select-label"
        value={data.status}
        onChange={handleChange}
        displayEmpty
        fullWidth
        className={statusIndicator(data.status)}
        IconComponent={() => <KeyboardArrowDownOutlinedIcon />}
      >
        <MenuItem

          onClick={() => setShowScheduleModel(true)}
          value={STATUS_CODE.DONE}
        >
          {STATUS_CODE.DONE}
        </MenuItem>
        <MenuItem value={STATUS_CODE.WAITING_FOR_RESPONSE}>
          {STATUS_CODE.WAITING_FOR_RESPONSE}
        </MenuItem>
        <MenuItem value={STATUS_CODE.CANCELLED}>
          {STATUS_CODE.CANCELLED}
        </MenuItem>
      </Select>

      <Nextschedule
        data={data}
        open={showScheduleModel}
        closeClick={closeClick}
      />
    </Box>
  )
}
export default StatusSelect
