import { Typography } from "@mui/material";
import React, { Fragment } from "react";

export default function Footer() {
  return (
    <Fragment>
      <Typography variant="body2" align="center">
        Copyright {new Date().getFullYear()}, Company Name. All Rights Reserved.
      </Typography>
    </Fragment>
  );
}
