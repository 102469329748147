import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import { UserContext } from '../Context/User/UserContext'
import Dashboard from '../pages/dashboard'
import Login from '../pages/Login'
import Splash from '../pages/splash/Splash'

const Navigation = () => {
  const { user, loading } = useContext(UserContext)
  if (loading) {
    return <Splash />
  }
  if (user) {
    return (
      <Routes>
        <Route path="/" element={<Dashboard />} />
      </Routes>
    )
  }
  return (
    <Routes>
      <Route path="/" element={<Login />} />
    </Routes>
  )
}
export default Navigation
