import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import React, { Fragment } from "react";
import "./style.css";

export default function Updatedetails({closeModal}) {
  return (
    <Fragment>
      <Typography variant="h2">Update Password</Typography>
      <Divider style={{ margin: "20px -24px" }} />

      <div className="form-group">
        <label>New Password</label>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="text"
          placeholder="Enter New Password"
          class="form-control"
        />
      </div>

      <div className="form-group">
        <label>Confirm New Password</label>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="text"
          placeholder="Re-enter New Password"
          class="form-control"
        />
      </div>

      <Box className="updatebtn">
        <Button
          variant="contained"
          color="primary"
          className="graybtn"
          onClick={closeModal}
        >
          Cancel
        </Button>

        <Button variant="contained" color="primary" onClick={closeModal}>
          Submit
        </Button>
      </Box>
    </Fragment>
  );
}
