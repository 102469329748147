import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
} from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import * as React from 'react'
import { Fragment } from 'react'
import { STATUS_CODE } from '../../Constants/StatusConst'
import { COLOR_CODE } from '../../util/Color'
import { ServiceContext } from '../../Context/Service/ServiceContext'
import { UserContext } from '../../Context/User/UserContext'
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: '20px' }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

export default function Drawertabs({ closeDrawer, clearAll }) {
  const [value, setValue] = React.useState(0)
  const { filterData, setFilterSwitch } = React.useContext(ServiceContext)
  // const [yearsArray, setYearsArray] = React.useState([])
  // const [yearselect, setYearselect] = React.useState('')
  const [monthselect, setMonthselect] = React.useState('')
  const [statusselect, setStatusselect] = React.useState('')
  const [assigneeselect, setAssigneeselect] = React.useState('')
  const { userData } = React.useContext(UserContext)

  // const initialYearState = () => {
  //   let yearArray = []
  //   let count = new Date().getFullYear()
  //   for (let i = count; i > count - 10; i--) {
  //     yearArray.push({ year: i, selected: false })
  //   }
  //   setYearsArray(yearArray)
  // }
  const monthArr = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'september',
    'October',
    'November',
    'December',
  ]
  // React.useEffect(() => {
  //   initialYearState()
  // }, [])
  const assignData = () => {
    return Object.keys(userData).map((userId) => {
      return (
        <FormControlLabel
          key={userId}
          control={<Checkbox />}
          checked={assigneeselect === `${userId}`}
          value={`${userId}`}
          onChange={handleCheckAssignee}
          label={
            <React.Fragment>
              <img
                src="https://i.picsum.photos/id/935/40/40.jpg?hmac=w-D0AGhltBSpFT8nIcrSlGRGRlY0SsunFfEqiMTPcRc"
                className="profile-img"
                width="18px"
                height="auto"
                alt=""
                style={{
                  marginRight: '5px',
                  borderRadius: '100%',
                  verticalAlign: 'middle',
                }}
              />
              {userData[userId]?.fullName}
            </React.Fragment>
          }
        />
      )
    })
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleCheckMonth = (e) => {
    setMonthselect(monthselect === e.target.value ? '' : e.target.value)
  }
  const handleCheckStatus = (e) => {
    setStatusselect(e.target.value)
  }
  const handleCheckAssignee = (e) => {
    setAssigneeselect(e.target.value)
  }
  const applyClick = () => {
    closeDrawer()
    const data = {
      // yearSelect: yearselect.toString(),
      monthSelect: monthselect,
      statusSelect: statusselect,
      assigneeSelect: assigneeselect,
    }
    setFilterSwitch(true)
    filterData(data)
  }
  const clearall = () => {
    setStatusselect('')
    setAssigneeselect('')
    setMonthselect('')
    // initialYearState()
    closeDrawer()
    clearAll()
  }

  // const getYearField = () => {
  //   return yearsArray?.map((year, index) => {
  //     return (
  //       <FormControlLabel
  //         key={index}
  //         checked={year.selected}
  //         onChange={() => {
  //           let data = _.map(yearsArray, (data) => {
  //             if (data.year === year.year)
  //               return { ...data, selected: !year.selected }
  //             else return { ...data, selected: false }
  //           })
  //           setYearselect(year.year)
  //           setYearsArray(data)
  //         }}
  //         value={year.year}
  //         control={<Checkbox />}
  //         label={year.year}
  //       />
  //     )
  //   })
  // }
  const getMonthField = () => {
    return monthArr?.map((month) => {
      return (
        <FormControlLabel
          key={month}
          checked={monthselect === month}
          onChange={handleCheckMonth}
          value={month}
          control={<Checkbox />}
          label={month}
        />
      )
    })
  }

  return (
    <Fragment>
      <Paper elevation={0} className="filterheader">
        <Typography variant="h2">Filter</Typography>
        <a href=" " onClick={() => clearall()}>
          Clear all
        </a>
      </Paper>

      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
          height: '100%',
        }}
        className="tabfilter"
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            background: COLOR_CODE.HEADING_BACKGROUND_AND_BORDERS,
            width: '125px',
          }}
        >
          {/* <Tab disableRipple={true} label="Year" {...a11yProps(0)} /> */}
          <Tab disableRipple={true} label="Month" {...a11yProps(0)} />
          <Tab disableRipple={true} label="Status" {...a11yProps(1)} />
          <Tab disableRipple={true} label="Assignee" {...a11yProps(2)} />
        </Tabs>

        {/* <TabPanel className="tabscrollable" value={value} index={0}>
          <FormGroup>{getYearField()}</FormGroup>
        </TabPanel> */}
        <TabPanel className="tabscrollable" value={value} index={0}>
          <FormGroup>{getMonthField()}</FormGroup>
        </TabPanel>
        <TabPanel className="tabscrollable" value={value} index={1}>
          <FormGroup>
            <FormControlLabel
              checked={statusselect === STATUS_CODE.DONE}
              onChange={handleCheckStatus}
              value={STATUS_CODE.DONE}
              control={<Checkbox />}
              label={STATUS_CODE.DONE}
            />

            <FormControlLabel
              checked={statusselect === STATUS_CODE.WAITING_FOR_RESPONSE}
              onChange={handleCheckStatus}
              value={STATUS_CODE.WAITING_FOR_RESPONSE}
              control={<Checkbox />}
              label={STATUS_CODE.WAITING_FOR_RESPONSE}
            />

            <FormControlLabel
              checked={statusselect === STATUS_CODE.CANCELLED}
              onChange={handleCheckStatus}
              value={STATUS_CODE.CANCELLED}
              control={<Checkbox />}
              label={STATUS_CODE.CANCELLED}
            />
          </FormGroup>
        </TabPanel>
        <TabPanel className="tabscrollable" value={value} index={2}>
          <FormGroup>{assignData()}</FormGroup>
        </TabPanel>
      </Box>
      <ButtonGroup aria-label="text button group" className="applyclosebtn">
        <Button onClick={closeDrawer} disableRipple={true}>
          Close
        </Button>
        <Button onClick={applyClick} disableRipple={true}>
          Apply
        </Button>
      </ButtonGroup>
    </Fragment>
  )
}
