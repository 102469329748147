import KeyIcon from "@mui/icons-material/Key";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import { Fragment } from "react";
import Updatedetails from "./Updatedetails";

export default function Updatepassword({ handleSettingsClose }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    handleSettingsClose()
    setOpen(false);
  };

  return (
    <Fragment>
      <Typography onClick={handleClickOpen}>
        <KeyIcon sx={{ verticalAlign: "middle", marginRight: "5px" }} /> Update
        Password
      </Typography>

      <Dialog
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={open}
        className="updatepasswordmodal"
      >
        <DialogContent dividers onClose={handleClose}>
          <Updatedetails closeModal={handleClose} />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
