import { MoreVertOutlined } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material'
import React, { Fragment, useState } from 'react'
import Adddetailsform from '../Adddetailsform'
import Servicedetails from '../Servicedetails'

export default function ActionsMenu({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [servicedetails, setServicedetails] = React.useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const onClickView = () => {
    setServicedetails(true)
    handleClose()
  }
  const onClickEdit = () => {
    setOpenEdit(true)
    handleClose()
  }
  return (
    <Fragment>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={onClickView}>View</MenuItem>
        <MenuItem onClick={onClickEdit}>Edit</MenuItem>
        <MenuItem onClick={handleClose}>Delete</MenuItem>
      </Menu>

      <Servicedetails {...{ servicedetails, setServicedetails }} data={data} />
      <Dialog
        maxWidth={'md'}
        aria-labelledby="customized-dialog-title"
        open={openEdit}
      >
        <DialogContent dividers sx={{ borderRadius: '10px', padding: '20px' }}>
          <Adddetailsform
            closeModal={() => setOpenEdit(false)}
            data={data}
            isEdit={true}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
