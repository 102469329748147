import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import {
  Button,
  Divider,
  FormControl,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'
import React, { Fragment, useContext, useState } from 'react'
import { COLOR_CODE } from "../../util/Color"
import { STATUS_CODE } from '../../Constants/StatusConst'
import { UserContext } from '../../Context/User/UserContext'
import './style.css'

export default function CreateScheduleForm({
  onPressNext,
  closeModal,
  profile,
  handleProfile,
  setProfile,
  handleScheduleDateInput,
  isEdit,
}) {
  let error = true
  const [titleError, setTitleError] = useState('')
  const [cityError, setCityError] = useState('')
  const { userData } = useContext(UserContext)
  const { title, assignTo, status, note, location, service_schedule_date } =
    profile
  const { address, city } = location
  const showProfile = () => {
    setProfile({ ...profile })
    if (profile.title.trim() === '') {
      error = true
      setTitleError('*This field is required')
    } else {
      error = false
      setTitleError('')
    }
    if (profile.location.city.trim() === '') {
      error = true
      setCityError('*This field is required')
    } else {
      error = false
      setCityError('')
    }

    if (
      !error &&
      profile.title.trim() !== '' &&
      profile.location.city.trim() !== ''
    ) {
      onPressNext(2)
    }
  }

  const assignData = () => {
    return Object.keys(userData).map((userId) => {
      return (
        <MenuItem id="assignee" key={userId} value={userId}>
          {userData[userId].fullName}
        </MenuItem>
      )
    })
  }

  return (
    <Fragment>
      <Typography mb={'20px'} variant="h2">
        Add Details
      </Typography>
      <Divider sx={{ margin: '0 -20px 20px -20px' }} />
      <form noValidate className="adddetailsmain">
        <div className="form-group">
          <label>Title</label>
          <TextField
            disabled={isEdit ? true : false}
            variant="outlined"
            margin="normal"
            required
            fullwidth="true"
            value={title ? title : ''}
            name="title"
            placeholder="Enter Title"
            className="form-control"
            onChange={handleProfile}
          />
          {titleError !== '' ? (
            <text style={{ color: COLOR_CODE.ERROR_COLOR, fontSize: '14px' }}>{titleError}</text>
          ) : null}
        </div>
        <div className="form-group">
          <label>Assign to</label>
          <Select
            id="assignTo"
            placeholder="Select Status"
            labelId="demo-simple-select-label"
            className="form-control"
            value={assignTo}
            variant="outlined"
            name="assignTo"
            required
            inputProps={{
              itemID: 'assignee',
            }}
            fullWidth
            onChange={handleProfile}
            displayEmpty
            IconComponent={() => <KeyboardArrowDownOutlinedIcon />}
            style={{
              boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              color: 'green !important',
            }}
          >
            <MenuItem id="assignee" value="">
              Select assignee
            </MenuItem>
            {assignData()}
          </Select>
        </div>
        <div className="form-group">
          <label>Address</label>
          <TextField
            variant="outlined"
            margin="normal"
            required
            value={address}
            fullwidth="true"
            name="address"
            placeholder="Enter Address"
            className="form-control"
            onChange={handleProfile}
          />
        </div>

        <div className="form-group">
          <label>City</label>
          <TextField
            variant="outlined"
            margin="normal"
            required
            value={city ? city : ''}
            fullwidth="true"
            name="city"
            placeholder="Enter City"
            className="form-control"
            onChange={handleProfile}
          />
          {cityError !== '' ? (
            <text style={{ color: COLOR_CODE.ERROR_COLOR, fontSize: '14px' }}>{cityError}</text>
          ) : null}
        </div>

        <Box className="massigesdatepicker">
          <div className="datemassige">
            <div className="form-group">
              <label>Select Date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={moment(
                    service_schedule_date?.join
                      ? service_schedule_date?.join(' ')
                      : service_schedule_date,
                    'DD MMMM YYYY'
                  ).toDate()}
                  id="date"
                  inputFormat="DD MMMM YYYY"
                  disableMaskedInput={true}
                  onChange={(newValue) => {
                    const value = moment(newValue.toString()).format(
                      'DD MMMM YYYY'
                    )
                    handleScheduleDateInput(value)
                  }}
                  renderInput={(params) => (
                    <TextField style={{ with: '100%' }} {...params} />
                  )}
                  placeholder="Enter Location"
                />
              </LocalizationProvider>
            </div>
            <div className="form-group">
              <label>Status</label>
              <FormControl>
                <Select
                  id="Status"
                  labelId="demo-simple-select-label"
                  className="form-control"
                  value={status}
                  variant="outlined"
                  name="status"
                  required
                  inputProps={{
                    itemID: 'Status',
                  }}
                  fullWidth
                  onChange={handleProfile}
                  displayEmpty
                  IconComponent={() => <KeyboardArrowDownOutlinedIcon />}
                  style={{
                    boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
                  }}
                >
                  <MenuItem value="">Select Status</MenuItem>
                  <MenuItem value={STATUS_CODE.DONE}>
                    {STATUS_CODE.DONE}
                  </MenuItem>
                  <MenuItem value={STATUS_CODE.WAITING_FOR_RESPONSE}>
                    {STATUS_CODE.WAITING_FOR_RESPONSE}
                  </MenuItem>
                  <MenuItem value={STATUS_CODE.CANCELLED}>
                    {STATUS_CODE.CANCELLED}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="form-group textareamodal">
            <label>Note</label>
            <TextareaAutosize
              disabled={isEdit ? true : false}
              name="note"
              color="primary"
              maxRows={6}
              variant="outlined"
              fullwidth="true"
              value={note.length > 0 ? note[note.length - 1].note : ''}
              aria-label="maximum height"
              placeholder="Enter Note"
              onChange={handleProfile}
            />
          </div>
        </Box>
        <div className="fullwidthcontactdetails">
          <Button
            onClick={closeModal}
            style={{ letterSpacing: '0.1em', color: '#000', width: 150 }}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => showProfile()}
            style={{ letterSpacing: '0.1em', width: 150 }}
            variant="contained"
            color="primary"
          >
            Next
          </Button>
        </div>
      </form>
    </Fragment>
  )
}
