import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import {
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { doc, setDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useEffect } from 'react'

import { Fragment } from 'react'
import { AlertContext } from '../../Context/Alert/AlertContext'
import { UserContext } from '../../Context/User/UserContext'
import { database, functions } from '../../firebase'
import { COLOR_CODE } from '../../util/Color'
import StatusSelect from '../StatusSelect/StatusSelect'

const Servicedetailsdrawer = ({ closeserviceDrawer, data }) => {
  const [statuscheck, setStatuscheck] = React.useState('')
  const { userData } = React.useContext(UserContext)
  const { setShow, setMessage } = React.useContext(AlertContext)
  const [Notes, setNotes] = React.useState([])
  const [showAllNotes, setshowAllNotes] = React.useState(false)
  const { fetchUserData, usersArray } = React.useContext(UserContext)

  const fcmToken = _.map(usersArray, (user) => {
    return user.fcmToken
  }).filter((data) => {
    if (data !== '') {
      return data
    }
  })
  useEffect(() => {
    let sorted_notes = _.orderBy(data.note, 'createdAt', 'desc')
    setNotes(sorted_notes)
  }, [data])
  const handleChange = async (event) => {
    setStatuscheck(event.target.value)
    data.assignTo = event.target.value
    const docRef = doc(database, 'SERVICES', data.id)
    fetchUserData()

    const statusData = {
      ...data,
      assignTo: event.target.value,
    }
    setDoc(docRef, statusData)
      .then((docRef) => {
        setShow(true)
        setMessage({ text: 'assign Updated', type: 'success' })
      })
      .catch((error) => {
        setShow(true)
        setMessage({ text: error, type: 'error' })
      })

    const sendPushToAll = httpsCallable(functions, 'sendServiceNotification')
    await sendPushToAll({
      title:
        data.title + ' assign to ' + userData[event.target.value]?.fullName,
      body:
        'Admin has been assign service to ' +
        userData[event.target.value]?.fullName,
      usersFcmToken: fcmToken,
      assignTo: event.target.value,
      assignee: 'Admin',
      serviceId: data.id,
    })
  }
  const handleClick = () => {
    closeserviceDrawer()
  }

  const assignData = () => {
    return Object.keys(userData).map((userId) => {
      return (
        <MenuItem key={userId} value={`${userId}`}>
          <img
            src="https://i.picsum.photos/id/935/40/40.jpg?hmac=w-D0AGhltBSpFT8nIcrSlGRGRlY0SsunFfEqiMTPcRc"
            className="profile-img"
            width="18px"
            height="auto"
            alt=""
            style={{
              marginRight: '5px',
              borderRadius: '100%',
              verticalAlign: 'middle',
            }}
          />
          {userData[userId].fullName}
        </MenuItem>
      )
    })
  }
  const renderShowAllNotes = (item, index) => {
    let date = moment(new Date(item.createdAt.toDate())).format('DD MMMM YYYY')

    if (index !== 0)
      return (
        <TableRow
          className="assigneetitles"
          key={index}
          style={{
            borderBottom: 'solid 1px ',
            borderBottomColor: COLOR_CODE.HEADING_BACKGROUND_AND_BORDERS,
          }}
        >
          <TableCell className="notes-H5">{item.note}</TableCell>
          <TableCell className="notes-H6">{date}</TableCell>
        </TableRow>
      )
  }
  return (
    <Fragment>
      <Paper elevation={0} className="filterheader">
        <Typography variant="h2">Service Detail</Typography>
      </Paper>

      <Box
        className="assigneedetails"
        sx={{
          height: 'calc(100vh - 200px)',
          padding: '20px',
          overflow: 'auto',
          flexDirection: 'column',
        }}
      >
        <div className="assigneetitles">
          <Typography variant="h4">Title</Typography>
          <Typography variant="h5">{data.title}</Typography>
        </div>
        <div className="assigneetitles">
          <Typography variant="h4">Company Name</Typography>
          <Typography variant="h5">{data.companyName}</Typography>
        </div>
        <div className="assigneetitles">
          <Typography variant="h4">Date</Typography>
          <Typography variant="h5">{data.service_schedule_date}</Typography>
        </div>

        <div className="assigneetitles">
          <Typography variant="h4">Status</Typography>
          <StatusSelect selectedStatus={data.status} data={data}></StatusSelect>
        </div>

        <div className="assigneetitles">
          <Typography variant="h4">Assignee</Typography>
          <Select
            labelId="demo-simple-select-label"
            value={data.assignTo}
            onChange={handleChange}
            displayEmpty
            color="secondary"
            className="assigneetitlesselectinput"
            IconComponent={() => <KeyboardArrowDownOutlinedIcon />}
            sx={{ width: '190px' }}
          >
            {assignData()}
          </Select>
        </div>
        <div className="assigneetitles">
          <Typography variant="h4">Contact Person Name </Typography>
          <Typography variant="h5">{data.contact_person_name}</Typography>
        </div>
        <div className="assigneetitles">
          <Typography variant="h4">Contact Number </Typography>
          <Typography variant="h5">{data.contact_number}</Typography>
        </div>
        <div className="assigneetitles">
          <Typography variant="h4">Location</Typography>
          <Typography variant="h5">
            {data.location?.address},{data.location?.city}
          </Typography>
        </div>

        <div className="assigneetitles">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="notes-H4">Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                className="assigneetitles"
                style={{
                  borderBottom: 'solid 0.5px',
                  borderBottomColor: COLOR_CODE.HEADING_BACKGROUND_AND_BORDERS,
                  marginTop: '10px',
                }}
              >
                <TableCell className="notes-H5">{Notes[0]?.note}</TableCell>
                <TableCell className="notes-H6">
                  {moment(new Date(Notes[0]?.createdAt.toDate())).format(
                    'DD MMMM YYYY'
                  )}
                </TableCell>
              </TableRow>
              {showAllNotes
                ? _.map(Notes, (note, index) => {
                    return renderShowAllNotes(note, index)
                  })
                : null}
            </TableBody>
          </Table>
        </div>
        {Notes.length > 1 ? (
          <div style={{ textAlign: 'center' }}>
            <button
              onClick={() => setshowAllNotes(!showAllNotes)}
              className="viewbtn"
            >
              {showAllNotes ? 'View less' : 'View More'}
            </button>
          </div>
        ) : null}
      </Box>

      <div className="assigneebtens">
        <Button
          className="graybtn"
          onClick={closeserviceDrawer}
          disableRipple={true}
        >
          Close
        </Button>
        <Button onClick={handleClick} variant="contained" disableRipple={true}>
          Save
        </Button>
      </div>
    </Fragment>
  )
}
export default Servicedetailsdrawer
