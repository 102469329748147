import { Alert, Snackbar } from '@mui/material'
import { React, useContext } from 'react'
import { AlertContext } from '../../Context/Alert/AlertContext'

export default function CustomSnackbar() {
  const { show, message, handleClose } = useContext(AlertContext)

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={show}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={message.type === '' ? 'warning' : message.type}
          sx={{ width: '100%' }}
        >
          {message.text}
        </Alert>
      </Snackbar>
    </div>
  )
}
