import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Navigation from './Navigation'
import { createTheme, ThemeProvider } from '@mui/material'
import { UserProvider } from './Context/User/UserProvider'
import AlertProvider from './Context/Alert/AlertProvider'
import CustomSnackbar from './component/Snackbar'
import ServiceProvider from './Context/Service/ServiceProvider'
import { COLOR_CODE } from '../src/util/Color'

const App = () => {
  const theme = createTheme({
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '5px 10px',
            color: COLOR_CODE.BLACK,
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '0',
            color: COLOR_CODE.BLACK,
            marginRight: '7.5px',
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            color: COLOR_CODE.BLACK,
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            marginBottom: '20px',
            marginLeft: 0,
            padding: '0',
            color: COLOR_CODE.BLACK,
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            color: COLOR_CODE.BLACK,
          },
        },
      },

      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: COLOR_CODE.BLACK,
          },
        },
      },

      MuiPickersFadeTransitionGroup: {
        styleOverrides: {
          root: {
            color: COLOR_CODE.BLACK,
          },
        },
      },

      MuiTableHead: {
        styleOverrides: {
          root: {
            '& .MuiTableCell-head': {
              color: COLOR_CODE.HEADING_TEXT,
              background: COLOR_CODE.HEADING_BACKGROUND_AND_BORDERS,
              fontWeight: '700',
              padding: '10px',
              fontSize: '12px',
              lineHeight: '15px',
            },
          },
        },
      },

      MuiTableContainer: {
        styleOverrides: {
          root: {
            '& .MuiTableCell-body': {
              color: COLOR_CODE.BLACK,
              background: COLOR_CODE.WHITE,
              fontWeight: '400',
              padding: '10px',
              fontSize: '14px',
              lineHeight: '18px',
              textAlign: 'left',
            },
          },
        },
      },

      MuiAppBar: {
        styleOverrides: {
          root: {
            background: COLOR_CODE.WHITE,
            boxShadow: 'none',
            borderBottom: '1px solid ',
            borderBottomColor: COLOR_CODE.HEADING_BACKGROUND_AND_BORDERS,
            padding: '5px 0',
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: '700',
            fontSize: '15px',
            lineHeight: '20px',
            textAlign: 'center',
            borderRadius: '10px',
            color: COLOR_CODE.WHITE,
            padding: '15px 25px',
            boxShadow: 'none',
            letterSpacing: '0.1em',
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          badge: {
            color: COLOR_CODE.WHITE,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            border: '1px solid ',
            borderColor: COLOR_CODE.HEADING_BACKGROUND_AND_BORDERS,
            borderRadius: 10,
            color: COLOR_CODE.BLACK,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          inputProps: {
            style: {
              color: COLOR_CODE.BLACK,
              height: '50px',
              padding: '0 15px',
              borderRadius: '10px',
            },
          },
        },
      },
    },
    palette: {
      primary: {
        main: COLOR_CODE.BLUE_THEME_COLOR,
      },
      secondary: {
        main: COLOR_CODE.BUTTON_GRAY_BACKGROUND,
      },
      success: {
        main: COLOR_CODE.STATUS_DONE_COLOR,
        // #2CDA00
      },
      warning: {
        main: COLOR_CODE.STATUS_WAITING_COLOR,
        // #F1712A
      },
      error: {
        main: COLOR_CODE.ERROR_COLOR,
        // #FF0000
      },
      text: {
        primary: COLOR_CODE.BLUE_THEME_COLOR,
        secondary: COLOR_CODE.HEADING_TEXT,
      },
    },
    typography: {
      fontFamily: 'google_sansregular',
      subtitle1: {
        fontSize: '16px',
        margin: '0',
      },
      h1: {
        fontWeight: 700,
        fontSize: '50px',
        lineHeight: '64px',
        letterSpacing: '0.1px',
      },

      h2: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '30px',
        letterSpacing: '0.1px',
      },

      body2: {
        borderTop: 'solid 1px ',
        borderTopColor: COLOR_CODE.HEADING_BACKGROUND_AND_BORDERS,
        padding: '10px 0',
        color: COLOR_CODE.GRAY_TEXT_COLOR,
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '18px',
      },
    },
  })

  return (
    <div className="App">
      <ServiceProvider>
        <AlertProvider>
          <CustomSnackbar />
          <UserProvider>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <Navigation />
              </BrowserRouter>
            </ThemeProvider>
          </UserProvider>
        </AlertProvider>
      </ServiceProvider>
    </div>
  )
}

export default App
