import { Search } from '@mui/icons-material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import {
  Badge,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { ServiceContext } from '../../Context/Service/ServiceContext'
import Adddetailsmodal from '../Adddetailsmodal'
import Drawerfilter from '../Drawerfilter'
import './style.css'

export default function Searchbar({ filter, clearAll, searchData }) {
  const { setSearchSwitch, filterSearch } = useContext(ServiceContext)
  const [searchText, setSearchText] = useState('')
  const [openDrawer, setOpenDrawer] = React.useState(false)

  const handleChange = (event) => {
    searchData(event.target.value)
    setSearchText(event.target.value)
    filterSearch(event.target.value)
  }

  const handleSearch = () => {
    filterSearch(searchText)
    setSearchSwitch(true)
  }
  const closeDrawer = () => {
    setOpenDrawer(false)
  }
  return (
    <div className="searchbar-main">
      <div className="leftsearchbar">
        <TextField
          className="searchfield"
          placeholder="Search by Title/Company Name"
          value={searchText}
          required
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="Search" edge="end" disableRipple={true}>
                  <Paper elevation={0} className="serchicon">
                    <Search onClick={handleSearch} />
                  </Paper>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Badge badgeContent={5} className="whitecolor" color="primary">
          <Button
            aria-label="show 17 new notifications"
            color="error"
            style={{ letterSpacing: '0' }}
            className="filterbtn"
            onClick={() => setOpenDrawer(true)}
            startIcon={<FilterAltIcon />}
          >
            Filter
          </Button>
        </Badge>

        <Drawerfilter
          {...{ openDrawer, closeDrawer }}
          filter={filter}
          clearAll={clearAll}
          setOpenDrawer
        />
      </div>

      <Adddetailsmodal />
    </div>
  )
}
