import { CircularProgress } from '@mui/material'
import React from 'react'
import './style.css'
export default function Splash() {
  return (
    <div className="container">
      <CircularProgress />
    </div>
  )
}
