import { Button, Divider, TextField, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { doc, setDoc, Timestamp } from 'firebase/firestore'
import moment from 'moment'
import * as React from 'react'
import { Fragment, useState } from 'react'
import { AlertContext } from '../../Context/Alert/AlertContext'
import { ServiceContext } from '../../Context/Service/ServiceContext'
import { database, functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import { UserContext } from '../../Context/User/UserContext'
import _ from 'lodash'

export default function Nextschedule({ open, data, closeClick }) {
  const [next_schedule_date, setNext_schedule_date] = useState('')
  const [noteData, setnoteData] = useState('')
  const { setShow, setMessage } = React.useContext(AlertContext)
  const { fetchServicesData } = React.useContext(ServiceContext)
  const { usersArray } = React.useContext(UserContext)
  const fcmToken = _.map(usersArray, (user) => {
    return user.fcmToken
  }).filter((data) => {
    if (data !== '') {
      return data
    }
  })
  const submitClick = () => {
    let notes = data.note
    notes.push({ createdAt: Timestamp.now(), note: noteData })
    const updateValue = {
      ...data,
      next_schedule_date: next_schedule_date,
      note: notes,
    }
    setDoc(doc(database, 'SERVICES', data.id), updateValue)
      .then(async () => {
        setShow(true)
        setMessage({ text: 'Date Stored!', type: 'success' })
        closeClick()
        fetchServicesData()

        const sendPushToAll = httpsCallable(
          functions,
          'sendServiceNotification'
        )
        await sendPushToAll({
          title: data.title + ' status changed to ' + data.status,
          body: '',
          usersFcmToken: fcmToken,
          serviceId: data.id,
          assignTo: data.assignTo
        })
      })
      .catch((err) => {
        setShow(true)
        setMessage({ text: err.message, type: 'error' })
      })
  }
  return (
    <Fragment>
      <Dialog
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={closeClick}
      >
        <DialogContent dividers>
          <Fragment>
            <Typography variant="h2">Next Schedule</Typography>
            <Divider style={{ margin: '20px -24px' }} />

            <div className="form-group">
              <label>Select Date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={next_schedule_date}
                  id="date"
                  inputFormat="DD MMMM YYYY"
                  disableMaskedInput={true}
                  onChange={(newValue) => {
                    const value = moment(newValue.toString()).format(
                      'DD MMMM YYYY'
                    )
                    setNext_schedule_date(value)
                  }}
                  renderInput={(params) => (
                    <TextField style={{ with: '100%' }} {...params} />
                  )}
                  placeholder="Enter Location"
                />
              </LocalizationProvider>
            </div>

            <div className="noteschedule">
              <label>Note</label>
              <TextField
                className=""
                multiline
                rows={4}
                variant="outlined"
                onChange={(text) => setnoteData(text.target.value)}
                defaultValue=""
              />
            </div>
            <Button
              style={{
                letterSpacing: '0.1em',
                marginTop: '30px',
                width: '100%',
              }}
              variant="contained"
              color="primary"
              onClick={submitClick}
            >
              Submit
            </Button>
          </Fragment>{' '}
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
