import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import * as React from "react";
import Notificationdetails from "./notificationdetails";
import "./style.css";

export default function Notificationdrawer({
  notificationDrawer,
  setNotificationDrawer,
}) {
  const closenotificationdrawer = () => {
    setNotificationDrawer(false);
  };
  const list = () => (
    <Box style={{ width: 400 }} role="presentation">
      <Notificationdetails closenotificationdrawer={closenotificationdrawer} />
    </Box>
  );

  return (
    <div>
      <SwipeableDrawer
        anchor={"right"}
        open={notificationDrawer}
        onClose={closenotificationdrawer}
        onOpen={() => setNotificationDrawer(true)}
        sx={{ borderRadius: 0 }}
        className="filtermolmain"
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
}
