import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import * as React from 'react'
import Drawertabs from './drawertabs'
import './style.css'

export default function Drawerfilter({
  openDrawer,
  closeDrawer,
  filter,
  clearAll,
  setOpenDrawer,
}) {
  const list = () => (
    <Box
      style={{ width: 400, height: 'calc(100% - 125px)' }}
      role="presentation"
    >
      <Drawertabs
        closeDrawer={closeDrawer}
        filterData={filter}
        clearAll={clearAll}
      />
    </Box>
  )

  return (
    <div>
      <SwipeableDrawer
        anchor={'right'}
        open={openDrawer}
        onClose={closeDrawer}
        sx={{ borderRadius: 0 }}
        className="filtermolmain"
        onOpen={() => setOpenDrawer(true)}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  )
}
