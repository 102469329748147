import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import * as React from 'react'
import Servicedetailsdrawer from './Servicedetailsdrawer'
import './style.css'

export default function Servicedetails({
  servicedetails,
  setServicedetails,
  data,
}) {
  const closeserviceDrawer = () => {
    setServicedetails(false)
  }
  const list = () => (
    <Box style={{ width: 400 }} role="presentation">
      <Servicedetailsdrawer
        closeserviceDrawer={closeserviceDrawer}
        data={data}
      />
    </Box>
  )

  return (
    <div>
      <SwipeableDrawer
        anchor={'right'}
        open={servicedetails}
        onClose={closeserviceDrawer}
        onOpen={() => setServicedetails(true)}
        sx={{ borderRadius: 0 }}
        className="filtermolmain"
      >
        {list()}
      </SwipeableDrawer>
    </div>
  )
}
