import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import React, { useContext, useState } from 'react'

import { addDoc, collection, getDocs } from 'firebase/firestore'
import { AlertContext } from '../../Context/Alert/AlertContext'
import { app, database } from '../../firebase'
import './style.css'

export default function Login() {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [loader, setLoader] = useState(false)
  const { setShow, setMessage } = useContext(AlertContext)
  const auth = getAuth(app)
  const login = (e) => {
    setLoader(true)
    e.preventDefault()
    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {})
      .catch((error) => {
        const errorCode = error.code
        if (errorCode === 'auth/wrong-password') {
          setMessage({ text: 'Wrong email or password!', type: 'error' })
        } else if (errorCode === 'auth/too-many-requests') {
          setMessage({
            text: 'Access has been temporarily disabled due to many failed login attempts!',
            type: 'error',
          })
        } else {
          setMessage({ text: 'Something went wrong!', type: 'error' })
        }
        setShow(true)
        // ..
      })
      .finally(() => {
        setLoader(false)
      })

    const userRef = collection(database, 'USERS')
    const getData = () => {
      getDocs(userRef).then((response) => {})
    }
    addDoc(userRef, { email: email })
      .then(() => {
        setShow(true)
        setMessage({ text: 'email Stored!', type: 'success' })
      })
      .catch((err) => {
        setShow(true)
        setMessage({ text: err.message, type: 'error' })
      })
    getData()
  }

  return (
    <div className="loginpage">
      <Paper style={{ borderRadius: 10 }} elevation={0} className="loginbox">
        <Typography align="center" variant="h1" color="textPrimary">
          Welcome!
        </Typography>
        <Typography align="center" variant="subtitle1" color="textSecondary">
          Enter your details to Sign in.
        </Typography>

        <form>
          <div className="form-group">
            <label>Email Id</label>
            <TextField
              variant="outlined"
              margin="normal"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              type={'email'}
              name="email"
              placeholder="Enter your Email Id"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              type="password"
              placeholder="Enter your Password"
              className="form-control"
            />
          </div>
          <Box style={{ marginTop: 50 }}>
            <Button
              disabled={loader}
              onClick={login}
              style={{ letterSpacing: '0.2em' }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              {loader ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                'Sign In'
              )}
            </Button>
          </Box>
        </form>
      </Paper>
    </div>
  )
}
